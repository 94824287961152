import React, { useState, useEffect, useCallback, createContext } from 'react';
import DeckGL from '@deck.gl/react';
import { FlyToInterpolator, WebMercatorViewport} from '@deck.gl/core';
import { StaticMap } from 'react-map-gl';

import { createTheme, ThemeProvider } from '@mui/material/styles';
//テーマスタイル
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});


//form 
import FormControl from '@mui/material/FormControl';


//selector
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';


import { featureCollection as turf_featureCollection, bbox as turf_bbox} from '@turf/turf'


import getLayers from './Layers';


//teble出力コンポーネント
import Datatable  from './component/DataTable';

//詳細ウインド
import DetailWindow from "./component/DetailWindow";

//サイドメニュー
import SideMenu from './component/SideMenu';

//凡例
import Legend from './component/Legend';


//マップスタイル変更ボタン
import MapStyleChangeBtn from './component/MapStyleChangeBtn';

//データ読み込みステータスダイアログ
import LoadingDialog from './component/LoadingDialog';


//map初期設定読み込み
import {
    MAPBOX_ACCESS_TOKEN,
    MAPBOX_STYLE_URL,
    INITIAL_VIEW_STATE,
    INITIAL_LAYER_STATUS,
} from "./config";


import { loadVerra } from "./util/loaderVerra";
import { loadTiff } from "./util/loaderTiff";
import { loadPNG } from "./util/loaderPNG";






export default (props)=>{
    //ビューステート(カメラ)
    const [viwState, setViewState] = useState(INITIAL_VIEW_STATE);

    //ベースマップスタイル
    const [mapStyle, setMapStyle] = useState(MAPBOX_STYLE_URL["Light"]);

    //レイヤー情報
    const [layerStatus, setLayerStatus] = useState(INITIAL_LAYER_STATUS);

    //マウスカーソルハンドラ
    const [cursor, setCursor] = useState("move");

    //レイヤーステータスを更新する
    const updateLayerStatus = (id, key, value)=>{
        const current = { ...layerStatus }
        current[id][key] = value;
        setLayerStatus(current);
    }


    //セレクターの選択要素
    const [countryList, setCountryList] = useState(null);  
    const [methodologyList, setMethodologyList] = useState(null);
    const [statusList, setStatusList] = useState(null);

    
    //データ読み込みダイアログ
    const [loadDialogOpen, setLoadDialogOpen] = useState(false)


    //選択されたフィルタリング要素
    const [selectedKeys, setSelectedKeys] = useState({
        country:"",
        methodology:"",
        status:""
    });


    //プロジェクトの詳細表示を行うウインドウ
    const [detailWindow, setDetailWindow] = useState({
        url:"",
        title:"",
        body:"",
        open:false
    });



    //初期データの読み込み処理
    useEffect(()=>{

        //プロジェクトデータ(verra)を読み込み
        loadVerra(result => {
            updateLayerStatus("Forest_Carbon_Projects", "data", result["verraData"]);
            setCountryList(result["countryList"]);
            setMethodologyList(result["methodologyList"] );
            setStatusList(result["statusList"]);
        });
    },[])


    //[country, Methorodology, Status]のセレクターが変更されたら、表示するidのフィルタリング処理を行う
    useEffect(() => {

        if (!layerStatus["Forest_Carbon_Projects"].data) return;

        //条件に合うデータを抽出する
        const filterd = layerStatus["Forest_Carbon_Projects"].data.filter(d => {
            let flag = true;
            const country = selectedKeys.country;
            const methodology = selectedKeys.methodology;
            const status = selectedKeys.status

            if (country != "" && country != "all" && country != d.Country){ flag = false };
            if (methodology != "" && methodology != "all"  && methodology != d.Methodology){ flag = false};
            if (status != "" && status != "all" && status != d.Status) { flag = false };
            return flag;
        });


        //抽出したデータからidを収集する
        const idList = filterd.map(d => d.ID);


        updateLayerStatus("Forest_Carbon_Projects", "filter_showId", idList);
        

    
    }, [selectedKeys, layerStatus["Forest_Carbon_Projects"].data])


    //[country, Methorodology, Status]のセレクターが変更されたら、絞り込まれたマーカー全てが見える位置にカメラを移動する
    useEffect(() => { 
        if (layerStatus["Forest_Carbon_Projects"].filter_showId.length <= 0 ) return null;

        const currentData = layerStatus["Forest_Carbon_Projects"].data.filter(d => {
            return layerStatus["Forest_Carbon_Projects"].filter_showId.indexOf(d.ID) > -1;
        });

        const points = turf_featureCollection(currentData.map(d => d.point));
        const [minLng, minLat, maxLng, maxLat]  = turf_bbox(points);

        //座標変換を行うオブジェクトを生成する
        const viewportWebMercator = new WebMercatorViewport({
            width: document.querySelector("#deckgl-wrapper").clientWidth,
            height: document.querySelector("#deckgl-wrapper").clientHeight
        });        

        //指定されたバウンディボックスが画面内に丁度収まる緯度経度とズームレベルを算出する
        let { longitude, latitude, zoom } = viewportWebMercator.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat]
            ],
            {
                padding: 100 //地物を画面内に収める際に余白を設定する
            }
        );

        //詳細ウインドウが開いていたら閉じる
        setDetailWindow(v => {
            return {
                ...v,
                open:false
            }
        })

        //viewStateを更新しカメラを地物が収まる位置へ移動する
        setViewState((v) => {
            return {
                ...v, // current viewState
                latitude: latitude,
                longitude: longitude,
                zoom: (zoom > 12) ? 8 : zoom,
                transitionDuration: 1000,
                transitionInterpolator: new FlyToInterpolator()
            };
        });

    }, [layerStatus["Forest_Carbon_Projects"].filter_showId])


    //単体のプロジェクトが選択されたら、プロジェクト詳細ウインドを表示し、カメラを移動する。
    useEffect(() => {

        if (!layerStatus["Forest_Carbon_Projects"].filter_selectedId) return ;

        const target = layerStatus["Forest_Carbon_Projects"].data.filter(d => d.id == layerStatus["Forest_Carbon_Projects"].filter_selectedId)[0];
        const coordinaties = target.point.geometry.coordinates;


        setDetailWindow({
            url:target.url,
            title:target.Name,
            body: target.project_explanation,
            open:true
        });


        //viewStateを更新しカメラを地物が収まる位置へ移動する
        setViewState((v) => {
            return {
                ...v, // current viewState
                longitude: coordinaties[0],
                latitude: coordinaties[1] - 0.1,
                zoom: 8,
                transitionDuration: 1000,
                transitionInterpolator: new FlyToInterpolator()
            };
        });
    }, [layerStatus["Forest_Carbon_Projects"].filter_selectedId])


    //tif画像データを読み込む
    const setTiffData = (layerID) => {
        //既にデータ読み込み済みの場合は、再読み込みを行わない
        if (layerStatus[layerID].show && layerStatus[layerID].data.length == 0) {

            setLoadDialogOpen(true);
            const readTiffs = async () => {
                let tiffs = []

                for (let idx in layerStatus[layerID].data_files) {
                    const tiff = await loadTiff(layerStatus[layerID].data_files[idx]);
                    tiffs.push(tiff);
                }
                updateLayerStatus(layerID, "data", tiffs);
                setLoadDialogOpen(false);
            }
            readTiffs();
        }
    }

    //tifファイルがソースとなるレイヤーが表示(show=true)になったらtifデータを読み込む(初回のみ)
    useEffect(() => {
        setTiffData("Forest_Loss");
    }, [layerStatus["Forest_Loss"].show])

    useEffect(() => {
        setTiffData("Forest_Gain");
    }, [layerStatus["Forest_Gain"].show])    

    useEffect(() => {
        setTiffData("Forest_Cover");
    }, [layerStatus["Forest_Cover"].show])


    //PNG画像データを読み込む
    const setPNGData = (layerID) => {
        //既にデータ読み込み済みの場合は、再読み込みを行わない
        if (layerStatus[layerID].show && layerStatus[layerID].data.length == 0) {

            setLoadDialogOpen(true);
            const readPNGs = async () => {
                let PNGs = []

                for (let idx in layerStatus[layerID].data_files) {
                    const tiff = await loadPNG(layerStatus[layerID].data_files[idx]);
                    PNGs.push(tiff);
                }

                updateLayerStatus(layerID, "data", PNGs);
                setLoadDialogOpen(false);
            }
            readPNGs();
        }
    }

    //pngファイルがソースとなるレイヤーが表示(show=true)になったらpngデータを読み込む(初回のみ)
    useEffect(() => {
        setPNGData("GeoCarbon_forest_biomass");
    }, [layerStatus["GeoCarbon_forest_biomass"].show])



    //プロジェクト詳細ウインドを閉じる
    const closeDetailWindow = () => {
        setDetailWindow(v => {
            return {
                ...v,
                open: false
            }
        })
    }

    //セレクター選択時処理
    const handleshowVerraIDFilter = (e)=>{

        //単一選択を解除する

        updateLayerStatus("Forest_Carbon_Projects", "filter_selectedId", null);

        setSelectedKeys((current) => {
            const update = {}
            update[e.target.name] = e.target.value;
            return {
                ...current,
                ...update
            }
        })
    }

    //table rowがクリックされた際の処理
    const handlerOnTrClick = (id) => {
        updateLayerStatus("Forest_Carbon_Projects", "filter_selectedId", id);

    }

    //マーカークリック時処理
    const handlerOnMarkerClick = (e) =>{
        const id = e.object.ID;
        updateLayerStatus("Forest_Carbon_Projects", "filter_selectedId", id);

    }

    const handlerOnMapStyleChange = (e) =>{
        console.log("change", e)
        setMapStyle(MAPBOX_STYLE_URL[e])
    }


    //マウスホバーハンドラ
    const handlerOnMapHover = (d) => {
        if (d.layer) {
            setCursor("pointer");
            /* //特定のレイヤーでのみ変更したい場合は、レイヤーidで判別する
            if(d.layer.id == "geojson-layer"){
              setCursor("crosshair");
            }
            */
        } else {
            setCursor("move");
        }
    }    

    //ツールチップ表示
    const handlerOnToolTip = (d)=> {
        //マウスホバー位置に地物が存在するかチェックする
        if (!d || !d.object) return null;
        let obj = d.object;
        return {
            // tooltip内に出力するhtml要素を渡す
            html: `ID:${obj.id}<br/>${obj.Name}`,
            // tooltip内のhtmlに適用するstyleを設定する
            style: {
                position: "absolute",
                top:"-3em",
                left:"1em",
                width:"200px",
                fontSize: "0.5em",
                color:"white"
            }
        };
    }

    const handlerOnMapClick = (e)=>{
        if(e.layer == null){
            //詳細ウインドウが開いていたら閉じる
            closeDetailWindow();
        }
    }

    return (
        <>
        <div className="mapWrapper"
            style={{
                position: "relative",
                width:"100vw",
                height:"70vh",
                minHeight:500,
                padding:0,
                left:"50%",
                right:"50%",
                marginLeft:"-50vw",
                marginRight:"-50vw",
                overflow:"hidden"
            }}
        >
            <DeckGL
                controller={true}
                initialViewState={viwState}
                layers={
                    getLayers({
                        layerStatus,
                        handlerOnMarkerClick
                    })
                }
                onClick={handlerOnMapClick}
                onHover={handlerOnMapHover}
                getCursor={() => cursor}
                getTooltip={handlerOnToolTip}
        >
            <StaticMap
                mapStyle={mapStyle}
                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            />
            </DeckGL>
            {/*レイヤー選択*/}
                <SideMenu layerStatus={layerStatus} setLayerStatus={setLayerStatus}  />
            {/*マップスタイル変更 */}
                <MapStyleChangeBtn onChange={handlerOnMapStyleChange}/>
            {/* 凡例 */}
                <Legend layerStatus={layerStatus} />

            {/*詳細ウインドウ*/}
            <DetailWindow
                status={detailWindow}
                onClick={closeDetailWindow}
            />
        </div>
        <Box sx={{
            ml:"20vw",
            mr:1,
            width: "79vw", 
        }}>
            <Box sx={{ 

                display: 'flex', m: 1 }}>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                        id="demo-simple-select"
                        name="country"
                        labelId="demo-simple-select-label"
                        value={selectedKeys.country}
                        label="Country"
                        onChange={handleshowVerraIDFilter}
                    >
                        {countryList && countryList.map((key) => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                        <MenuItem key={"all"} value={"all"}>{"ALL"}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel id="demo-simple-select-label">Methodology</InputLabel>
                    <Select
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        name="methodology"
                        value={selectedKeys.methodology}
                        label="Methodology"
                        onChange={handleshowVerraIDFilter}
                    >
                        {methodologyList && methodologyList.map((key) => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                        <MenuItem key={"all"} value={"all"}>{"ALL"}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        name="status"
                        value={selectedKeys.status}
                        label="Status"
                        onChange={handleshowVerraIDFilter}
                    >
                        {statusList && statusList.map((key) => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                        <MenuItem key={"all"} value={"all"}>{"ALL"}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Datatable rows={
                layerStatus["Forest_Carbon_Projects"].data && layerStatus["Forest_Carbon_Projects"].data.filter(d => {
                    return layerStatus["Forest_Carbon_Projects"].filter_showId.indexOf(d.ID) > -1;
                })
            } selectedID={layerStatus["Forest_Carbon_Projects"].filter_selectedId} handlerOnTrClick={handlerOnTrClick} />
        </Box>


        <LoadingDialog open={loadDialogOpen}/>

    </>
    )

}