/// app.js
import React from 'react';
import ReactDOM from "react-dom";

 
import Dashboard from './DashBoard/index.js';


function App() {

    return (
        <div>
            <Dashboard />
        </div>
    );
}

ReactDOM.render(<App />, document.getElementById('app'));