import { ScatterplotLayer } from "deck.gl";
import { color as d3color } from "d3-color";

//16進のカラーコードをrgbの配列に変換する
d3color.prototype.toArray = function () { return [this.r, this.g, this.b] }


const iconConfig = {
    url: "./img/marker.png",
    x: 0,
    y: 0,
    anchorY: 512,
    width: 512,
    height: 512,
    mask: true
}


export default (props) => {
    const { data, colorScale, onClick } = props;

    const layer = new ScatterplotLayer({
        id: 'scatterplot-layer',
        data,
        pickable: true,
        opacity: 0.8,
        stroked: true,
        filled: true,
        radiusScale: 6,
        radiusMinPixels: 1,
        radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        getPosition: (d,i) => {
         return d.point.geometry.coordinates
        },
        radiusScale:100,
        getRadius: 10,
        radiusMinPixels:6,
        getFillColor: d =>{
            return d3color(colorScale(d["AFOLU Activities"])).toArray();
        },
        getLineColor: d => [0, 0, 0],
        onClick: onClick
    });


    return layer;
}
