import React, { useState, memo } from 'react';


import Box from '@mui/material/Box';

//タイポブラフィ
import Typography from '@mui/material/Typography';

//スライダー
import Slider from '@mui/material/Slider';


export default memo(({onValueChange }) => {
    const [rangeValue, setRanveValue] = useState([1, 20]);


    // スライダーレンジ移動時処理
    const handlerSliderOnChange = (e) => {
        const value = e.target.value;
        setRanveValue(value)
    }

    return (
        <Box sx={{ width: "90%", pl: 2, display: "flex" }}>
            <Typography variant="caption">Year</Typography>
            <Slider
                sx={{ 
                    ml: 4.5,
                    "& .MuiSlider-thumb": {
                        color: "#089bad",
                        width: 8,
                        borderRadius: 0
                    },
                    "& .MuiSlider-track": {
                        color: "#089bad",
                    }
                }}
                size="small"
                min={1}
                max={20}
                step={1}
                valueLabelFormat={value => 2000 + value}
                value={rangeValue}
                valueLabelDisplay="auto"
                onChange={handlerSliderOnChange}
                onChangeCommitted={onValueChange}
            />
        </Box>


    );
});