import { color } from "d3-color";
import { scaleThreshold } from "d3-scale";
import { interpolateYlGnBu } from "d3-scale-chromatic";

// 背景マップに使用するMapboxのトークン設定
export const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;


// 初期ビューポートの設定
export const INITIAL_VIEW_STATE = {
    latitude: 35.73202612464274,
    longitude: 137.53268402693763,
    zoom: 0
}

export const MAPBOX_STYLE_URL = {
    "Light":"mapbox://styles/mapbox/light-v10",
    "Dark":"mapbox://styles/mapbox/dark-v10",
    "Satellite":"mapbox://styles/mapbox/satellite-v9",
}


//レイヤーのステータス
export const INITIAL_LAYER_STATUS = {
    "Forest_Carbon_Projects": {
        show: true,
        name: "Forest Carbon Projects (Verra)",
        data:[],
        filter_showId:[], //地図上に表示するプロジェクトのidリスト
        filter_selectedId:null, //個別選択されたプロジェクトのid
        source: [
            { 
                label: "https://registry.verra.org/app/search/VCS",
                url: "https://registry.verra.org/app/search/VCS" 
            }
        ],
        description:null,
        legendType: "generate",
        colorScale:type => {
            let color = "#CCC"
            switch(type){

                case 'ALM':
                    color = "#FF00FF";
                    break;

                case'ARR':
                    color = "salmon";
                    break;
                case 'ARR; WRC':
                    color = "orange";
                    break;
                case 'ARR; REDD; WRC':
                    color = "sandybrown";
                    break;

                case 'ACoGS':
                    color = "red";
                    break;
                case 'ACoGS; REDD':
                    color = "darkred";
                    break;

                case 'IFM; REDD':
                    color = "blue";
                    break;
                case 'IFM', 'ARR; REDD':
                    color = "darkblue";
                    break;

                case 'REDD':
                    color = "green";
                    break;
                case 'REDD; WRC':
                    color = "darkgreen";
                    break;
            }

            return color;

        },
        legend: [
            { label: "ALM", color: "#C75DAB", height: 16 },

            { label: "ARR", color: "salmon", height: 16 },
            { label: "ARR; WRC", color: "orange", height: 16 },
            { label: "ARR; REDD; WRC", color: "sandybrown", height: 16 },

            { label: "ACoGS", color: "red", height: 16 },
            { label: "ACoGS; REDD", color: "darkred", height: 16 },

            { label: "IFM; REDD", color: "blue", height: 16 },
            { label: "IFM', 'ARR; REDD", color: "darkblue", height: 16 },

            
            { label: "REDD", color: "green", height: 16 },
            { label: "REDD; WRC", color: "darkgreen", height: 16 },

            { label: "Other", color: "#ccc", height: 16 }
        ]        
    },
    "Forest_Cover": {
        show: false,
        name: "Forest Cover",
        data: [],
        data_files: [
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_040W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_050W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_060W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_070W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_080W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_100E.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_110E.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_00N_120E.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10N_060W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10N_070W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10N_080W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10N_090E.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10N_100E.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10N_110E.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10S_050W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10S_060W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10S_070W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_10S_080W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_20S_050W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_20S_060W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_20S_070W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_30S_060W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_30S_070W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_30S_080W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_40S_070W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_40S_080W.tif.zip",
            "data/forest/cover/Hansen_GFC-2020-v1.8_treecover2000_50S_080W.tif.zip",
        ],
        filter_range: [1, 100], //表示するyearレンジ
        layer_opacity:1,
        source: [
            {
                label: "https://data.globalforestwatch.org/documents/tree-cover-loss/explore",
                url: "https://data.globalforestwatch.org/documents/tree-cover-loss/explore"
            }
        ],
        description: `Hansen, M. C., P. V. Potapov, R. Moore, M. Hancher, S. A. Turubanova, A. Tyukavina, D. Thau, S. V. Stehman, S. J. Goetz, T. R. Loveland, A. Kommareddy, A. Egorov, L. Chini, C. O. Justice, and J. R. G. Townshend. 2013. “High-Resolution Global Maps of 21st-Century Forest Cover Change.” Science 342 (15 November): 850–53. Data available on-line from: http://earthenginepartners.appspot.com/science-2013-global-forest.`,
        legendType:"generate",
        legend: [
            { label: "cover", color: "green", height:16 }
        ]
    },
    "Forest_Gain": {
        show: false,
        name: "Forest Gain",
        data: [],
        data_files: [
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_040W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_050W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_060W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_070W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_080W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_100E.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_110E.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_00N_120E.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10N_060W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10N_070W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10N_080W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10N_090E.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10N_100E.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10N_110E.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10S_050W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10S_060W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10S_070W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_10S_080W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_20S_050W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_20S_060W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_20S_070W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_30S_060W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_30S_070W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_30S_080W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_40S_070W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_40S_080W.tif.zip",
            "data/forest/gain/Hansen_GFC-2020-v1.8_gain_50S_080W.tif.zip"
        ],
        filter_range: [1, 2], //表示するyearレンジ
        layer_opacity: 1,
        source: [
            {
                label: "https://data.globalforestwatch.org/documents/tree-cover-loss/explore",
                url: "https://data.globalforestwatch.org/documents/tree-cover-loss/explore"
            }
        ],
        description: `Hansen, M. C., P. V. Potapov, R. Moore, M. Hancher, S. A. Turubanova, A. Tyukavina, D. Thau, S. V. Stehman, S. J. Goetz, T. R. Loveland, A. Kommareddy, A. Egorov, L. Chini, C. O. Justice, and J. R. G. Townshend. 2013. “High-Resolution Global Maps of 21st-Century Forest Cover Change.” Science 342 (15 November): 850–53. Data available on-line from: http://earthenginepartners.appspot.com/science-2013-global-forest.`,
        legendType: "generate",
        legend: [
            { label: "cover", color: "rgb(255, 0, 255)", height: 16 }
        ]
    },    
    "Forest_Loss": {
        show: false,
        name: "Forest Loss",
        data:[],
        data_files: [
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_040W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_050W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_060W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_070W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_080W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_100E.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_110E.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_00N_120E.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10N_060W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10N_070W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10N_080W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10N_090E.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10N_100E.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10N_110E.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10S_050W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10S_060W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10S_070W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_10S_080W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_20S_050W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_20S_060W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_20S_070W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_30S_060W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_30S_070W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_30S_080W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_40S_070W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_40S_080W.tif.zip",
            "data/forest/loss/Hansen_GFC-2020-v1.8_lossyear_50S_080W.tif.zip"
        ],
        filter_range:[1, 20], //表示するyearレンジ
        layer_opacity: 1,
        source: [
            { 
                label: "https://data.globalforestwatch.org/documents/tree-cover-loss/explore", 
                url: "https://data.globalforestwatch.org/documents/tree-cover-loss/explore" 
            }
        ],
        description: `Hansen, M. C., P. V. Potapov, R. Moore, M. Hancher, S. A. Turubanova, A. Tyukavina, D. Thau, S. V. Stehman, S. J. Goetz, T. R. Loveland, A. Kommareddy, A. Egorov, L. Chini, C. O. Justice, and J. R. G. Townshend. 2013. “High-Resolution Global Maps of 21st-Century Forest Cover Change.” Science 342 (15 November): 850–53. Data available on-line from: http://earthenginepartners.appspot.com/science-2013-global-forest.`,
        legendType: "generate",
        legend:[
            { label: "loss", color: "red", height: 16 }
        ]
    },
    "GeoCarbon_forest_biomass": {
        show: false,
        name: "GeoCarbon Forest Biomass",
        data: [],
        data_files: [
            "data/geocarbon/GEOCARBON_AGB_Map.png.zip",
        ],
        data_bbox: [
            [-180.0000000000000000, -56.0200000000000102, 180.0000000000000000, 84.0000000000000000],
        ],
        layer_opacity: 1,
        source: [
            {
                label: "https://www.wur.nl/en/research-results/chair-groups/environmental-sciences/laboratory-of-geo-information-science-and-remote-sensing/research/integrated-land-monitoring/forest_biomass.htm",
                url: "https://www.wur.nl/en/research-results/chair-groups/environmental-sciences/laboratory-of-geo-information-science-and-remote-sensing/research/integrated-land-monitoring/forest_biomass.htm"
            }
        ],
        description: ` Avitabile et al`,
        legendType: "generate",
        legend: [
            { label: "611.399963", color: interpolateYlGnBu(1), height: 8},
            { label: "", color: interpolateYlGnBu(0.9), height: 8 },
            { label: "", color: interpolateYlGnBu(0.8), height: 8 },
            { label: "", color: interpolateYlGnBu(0.7), height: 8 },
            { label: "", color: interpolateYlGnBu(0.6), height: 8 },
            { label: "", color: interpolateYlGnBu(0.5), height: 8 },
            { label: "", color: interpolateYlGnBu(0.4), height: 8 },
            { label: "", color: interpolateYlGnBu(0.3), height: 8 },
            { label: "", color: interpolateYlGnBu(0.2), height: 8 },
            { label: "", color: interpolateYlGnBu(0.1), height: 8 },
            { label: "0", color: interpolateYlGnBu(0), height: 8 }
        ]        
    },
    "Agricultural_Revenue": {
        show: false,
        name: "Agricultural Revenue",
        source: [
            {
                label:"https://dataverse.harvard.edu/dataverse/tropical_reforestation_study", 
                url:"https://dataverse.harvard.edu/dataverse/tropical_reforestation_study"
            }
        ],
        description: `Engelmann, Jens; Busch, Jonah Dr., 2019, "Replication Data for: Potential for Low - Cost Carbon Dioxide Removal through Tropical Reforestation", https://doi.org/10.7910/DVN/ZJTWVY, Harvard Dataverse, V5`,
        colorScale: scaleThreshold()
            .domain([
                0,
                990,
                2100,
                2200,
                2400,
                2700,
                3500
            ])
            .range([
                "rgb(90, 24, 70)",
                "rgb(144, 12, 63)",
                "rgb(199, 0, 57)",
                "rgb(227, 97, 28)",
                "rgb(241, 146, 14)",
                "rgb(255, 195, 0)"               
            ]),
        legendType: "generate",
        legend: [
            { label: "0 - 990", color: "rgb(90, 24, 70)", height: 16  },
            { label: "990 - 2100", color: "rgb(144, 12, 63)", height: 16  },
            { label: "2100 - 2400", color: "rgb(199, 0, 57)", height: 16  },
            { label: "2400 - 2700", color: "rgb(227, 97, 28)", height: 16  },
            { label: "2700 - 3500", color: "rgb(241, 146, 14)", height: 16  },
            { label: "3500 < ", color: "rgb(255, 195, 0)", height: 16 }
        ]
    },

}

