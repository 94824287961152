import React, {memo} from 'react';

//レイアウト用ボックス
import Box from '@mui/material/Box';

//タイポブラフィ
import Typography from '@mui/material/Typography';


export default memo(({ color, label, height }) => {

    return (

        <Box
            sx={{
                display: 'flex',
                alignItems: 'left',
                height: height,
            }}
        >
            <Box
                sx={{
                    width: 16,
                    height: height,
                    mb: 0,
                    mt: 0,
                    backgroundColor:color,
                }}
                variant="outlined" >
            </Box>
            <Typography sx={{
                fontSize: 11,
                ml: 1,
                mb: 0,
                mt: 0
            }} color="text.secondary" gutterBottom>
                {label}
            </Typography>
        </Box>
    )
});