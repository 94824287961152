import { BitmapLayer } from '@deck.gl/layers';


const convertImageData = (geoTiffDataRGB, width, height, range) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, width, height);
    const data = imageData.data;

    const isRange = (range, value) => {
        return (range[0] <= value && range[1] >= value)
    }


    for (let i = 0; i < height; i++) {
        for (let j = 0; j < width; j++) {
            const srcIdx = 3 * i * width + 3 * j;
            const idx = 4 * i * width + 4 * j;

            const value = geoTiffDataRGB[srcIdx];
            //if(Math.random() > 0.9) console.log(isRange(range, value))
            data[idx] = isRange(range, value) ? 255 : 0; 
            data[idx + 1] = 0 ;//geoTiffDataRGB[srcIdx + 1]
            data[idx + 2] = 0 ;//geoTiffDataRGB[srcIdx + 2];
            data[idx + 3] = isRange(range, value) ? 255 : 0;
        }
    }
    ctx.putImageData(imageData, 0, 0);
    return canvas
}

let memo_imageDataset = [];
let prev_filter_range = null;

export default (props) => {
    const { data, filter_range, layer_opacity } = props;
    if (!data || !data.length > 0) return null;


    let imageDataset = memo_imageDataset;

    if (imageDataset.length == 0 || prev_filter_range != filter_range){
        imageDataset = [];
        for (let idx in data) {
            const tiffData = data[idx];
            const img = convertImageData(tiffData.rgb, tiffData.width, tiffData.height, filter_range);
            imageDataset.push({
                ...data[idx],
                img
            });
        }
    } 

    prev_filter_range = filter_range

    let layes = [];

    for (let idx in imageDataset) {
        const layer = new BitmapLayer({
            id: 'forestLoss-layer' + imageDataset[idx].bbox.join(":"),
            pickable: false,
            opacity: layer_opacity,
            bounds: imageDataset[idx].bbox,
            image: imageDataset[idx].img,
        });

        layes.push(layer);
    }

    return layes;

}

