import { load } from '@loaders.gl/core';
import { JSONLoader } from '@loaders.gl/json';

import { point as turf_point} from '@turf/turf'


export const loadVerra = async (callback) => {
    let result = {}

    //スクレイピングで取得したデータ
    const scraping_data = await load("./data/verra/scraping_data.json?1", JSONLoader);
    //プロジェクトの緯度経度(ポイント)データ
    const complement_data = await load("./data/verra/complement_data.json?1", JSONLoader);

    
    scraping_data.forEach(d => {

        //idでマッチしたデータにポイントデータを付加
        const c = complement_data[d.id];
        if (!c) console.log("https://registry.verra.org/app/projectDetail/VCS/"+d.id)
        if(!c) {
            d.point = null;
        }else{
            d.point = turf_point([c.longitude, c.latitude]);
        };

        console.log(c.comp_kml)


        //KML補完フラグがtrueの場合は、データに追加する
        if (c.comp_kml) d.comp_kml = true;


        //verraへのリンクURLを生成する
        d.url = "https://registry.verra.org/app/projectDetail/VCS/" + d.ID
    });



    //pointデータが無いデータはひとまず削除する
    //complement_data.jsonにidと緯度経度を補完すれば表示されるようになる
    result["verraData"] = scraping_data.filter(d => d.point != null);

    //値を整形
    scraping_data.forEach(d => {
        if (d.Hectares) d.Hectares = d.Hectares.replace(/Hectares/, "");
    })

    //国名を収集(重複削除)
    const country = Array.from(new Set(scraping_data.map(d => d.Country)));
    const countryList = country.sort()
    result["countryList"] = countryList;

    //メソロジーを収集（重複削除）
    const methodology = Array.from(new Set(scraping_data.map(d => d.Methodology)));
    const methodologyList = methodology.sort().filter(d => {
        if (d == "") return null;
        return d
    })
    result["methodologyList"] = methodologyList;

    //ステータス収集（重複削除）
    const status = Array.from(new Set(scraping_data.map(d => d.Status)));
    const statusList = status.sort()
    result["statusList"] = statusList;

    if(callback) callback(result);

}