import React, { useState } from 'react';


import Divider from '@mui/material/Divider';

//ボックス
import Box from '@mui/material/Box';

//ペーパー
import Paper from '@mui/material/Paper';

//メニュー
import MenuList from '@mui/material/MenuList';


//アイコン
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


//ソース表示ダイアログ
import CitatioDialog from './CitatioDialog';

//メニューアイテム　共通ブロック
import CommonItem from './MenuItems/CommonItem';
import OptForestLossItem from './MenuItems/OptForestLossItem';
import OpactiyController from './MenuItems/OpactiyController';



export default ({ layerStatus, setLayerStatus }) => {
    const [open, setOpen] = useState(true);

    //引用情報ダイアログ
    const [citationDialog, setCitationDialog] = useState({
        title: null,
        source: [],
        description:null,
        open:false
    });



    //レイヤーステータスの表示・非表示を切り替え
    const handlerLayerSwitchToggle = (e) => {
        const key = e.currentTarget.value;
        const current = { ...layerStatus }
        current[key].show = !current[key].show;
        setLayerStatus(current);
    }


    //info ボタンクリック時処理
    const handlerOnInfoBtnClick = (e)=>{
        const key = e.currentTarget.value;
        const status = layerStatus[key];

        //引用情報ダイアログをオープン
        setCitationDialog({
            title: status.name,
            source: status.source,
            description: status.description,
            open:  true,
        });
    }

    //引用情報ダイアログ閉ハンドラ
    const handlerDialogClose = (e)=>{
        const current = { ...citationDialog }
        current.open = e;
        setCitationDialog(current);
    }

    //サイドメニューの開閉ハンドラ
    const handlerSideMenuToggle = ()=>{
        setOpen(v=> !v);
    }

    //forest loss yearレンジ決定時処理
    const handlerRangeValueChange = (e, value) => {
        const current = { ...layerStatus }
        current["Forest_Loss"].filter_range = value;
        setLayerStatus(current);        
    }

    const handlerLayerOpacityValueChange = (e)=>{
        const current = { ...layerStatus }
        current[e.key].layer_opacity = e.value;
        setLayerStatus(current);        
    }


    const allowDrop = (e) => {
        e.preventDefault();
    }

    const handlerOnItemDrop = (e) =>{
        e.preventDefault();
        console.log("E", e)
    }

    return (
        <div>
            <Paper 
                id="side-menu"
                className={open ? "slideIn" : "slideOut"}
                sx={{
                    position: "absolute",
                    borderRadius: "0px 0px 0px 0px",
                    top: 0,
                    left: 0,
                    pl: 2,
                    width: "20vw",
                    height: "100%",
                    backgroundColor: "white"

                }}
                variant="outlined" >
                {/* 開閉ボタン */}
                <Paper sx={{
                    position: "absolute",
                    top: 0,
                    right: -29.5,
                    cursor:"pointer", 
                    borderRadius:"0px 0px 4px 0px",
                    boxShadow:0,
                    backgroundColor:" #333",
                    color:"white",
                    pl:1,
                    pr:1,
                    pt:0.5,
                    pb:0.5
                }}
                onClick={handlerSideMenuToggle}
                >
                    { 
                        open ? <ArrowBackIosIcon sx={{ fontSize: 12 }} />: <ArrowForwardIosIcon sx={{ fontSize: 12 }} />  

                    }                    
                </Paper>

                {/* レイヤーメニュー */}
                <MenuList
                    sx={{
                        "& .MuiListItemIcon-root":{
                            minWidth:"0px !important"
                        },
                        "& .MuiSwitch-root":{
                            ml:"-0.25em",
                            mr:"-1em"
                        }
                    }}
                >
                    {Object.keys(layerStatus).map( (key, i)=> {
                       return ( 
                        <Box 
                            sx={{
                                borderLeft:"#089bad solid 4px", 
                                borderBottom: "#ccc solid 1px",
                                mb:0.5
                            }}
                            key={key}
                            draggable={true}
                            onDragOver={allowDrop}
                            onDrop={handlerOnItemDrop}
                        >
                            <CommonItem
                                layerID={key}
                                name={layerStatus[key].name}
                                show={layerStatus[key].show}
                                onInfoBtnClick={handlerOnInfoBtnClick}
                                onSwitchChange={handlerLayerSwitchToggle}

                            />
               
                            {(layerStatus[key].layer_opacity || layerStatus[key].layer_opacity==0) && (<OpactiyController layerKey={key} onValueChange={handlerLayerOpacityValueChange}/>)}
                            {key == "Forest_Loss" && ( <OptForestLossItem onValueChange={handlerRangeValueChange}/>)}

                        </Box>
                       ) 
                    } )}

                </MenuList>
            </Paper>
            <CitatioDialog status={citationDialog} handler={handlerDialogClose}/>

      
        </div>
    )
}