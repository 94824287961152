import React, { useState, memo } from 'react';


import Box from '@mui/material/Box';

//タイポブラフィ
import Typography from '@mui/material/Typography';

//スライダー
import Slider from '@mui/material/Slider';


export default memo(({ layerKey, onValueChange }) => {
    const [rangeValue, setRanveValue] = useState(1);


    // スライダーレンジ移動時処理
    const handlerSliderOnChange = (e) => {
        const value = e.target.value;
        setRanveValue(value)
    }

    const handlerChangeCommitted = (e, value)=>{
        const re = {
            key: layerKey,
            value:value
        }
        onValueChange(re);
    }


    return (
        <Box sx={{ width: "90%", pl: 2, display: "flex" }}>
            <Typography variant="caption">Opacity</Typography>
            <Slider
                sx={{ 
                    ml: 1.5 ,
                    "& .MuiSlider-thumb":{
                        color: "#089bad",
                        width:8,
                        borderRadius:0
                    },
                    "& .MuiSlider-track": {
                        color: "#089bad",
                    }                    
                }}
                size="small"
                min={0}
                max={1}
                step={0.1}
                valueLabelFormat={value => value}
                value={rangeValue}
                valueLabelDisplay="auto"
                onChange={handlerSliderOnChange}
                onChangeCommitted={handlerChangeCommitted}
            />
        </Box>


    );
});