
//zipファイルを読み込みパースするためのライブラリ
import { load, fetchFile, parse } from "@loaders.gl/core";
import { ZipLoader } from "@loaders.gl/zip";
import { ImageLoader } from '@loaders.gl/images';

import { fromArrayBuffer } from 'geotiff';

const readData = async (r, callback) => {
    const reader = r.body.getReader();

    // Step 2: 合計の長さを取得します
    const contentLength = +r.headers.get('Content-Length');

    // Step 3: データを読み込みます
    let receivedLength = 0; // その時点の長さ
    let chunks = []; // 受信したバイナリチャンクの配列(本文を構成します)
    while (true) {
        const { done, value } = await reader.read();

        if (done) {
            break;
        }

        chunks.push(value);
        receivedLength += value.length;
        if (callback) callback(~~(receivedLength / contentLength * 100));
    }

    // Step 4: チャンクを1つの Uint8Array に連結します
    let chunksAll = new Uint8Array(receivedLength); // (4.1)
    let position = 0;
    for (let chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
    }

    return chunksAll;

}

//tiff読み込み
export const loadPNG = async (url, setLoadReceived) => {


    const r = await fetchFile(url);
    const splitURL = url.split("/");
    const fileName = splitURL[splitURL.length - 1];

    const body = readData(r, function (rate) {
        setLoadReceived && setLoadReceived({
            fileName: fileName,
            rate: rate
        })
    });
    const zip = await load(body, ZipLoader);
    const key = Object.keys(zip)


    const png = parse(zip[key[0]], ImageLoader);

    return png;

}

