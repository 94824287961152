import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


const InfoDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiBackdrop-root': {
        "backgroundColor": "rgba(0, 0 , 0, 0.7)"
    }

}));



const InfoDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

InfoDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default memo((props) => {
    const { status, handler} = props;


    const handleClose = () => {
        handler(false);
    };

    return (
        <div>
            <InfoDialog
                sx={{
                    "& .MuiPaper-root":{
                        minWidth: 600
                    }
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={status.open}
            >
                <InfoDialogTitle
                    id="customized-dialog-title"
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold"
                    }}      
                    onClose={handleClose}>
                    {status.title}
                </InfoDialogTitle>
                <DialogContent dividers>
                    <Typography 
                        sx={{
                            fontSize:"1.2rem",
                            fontWeight:"bold"
                        }}
                        gutterBottom>
                        Description
                    </Typography>
                    <Typography gutterBottom>
                        {status.description}
                    </Typography>



                    <Typography 
                        sx={{
                            fontSize: "1.2rem",
                            fontWeight: "bold"
                        }}      
                        gutterBottom>
                        Source
                    </Typography>
                    {status.source.map((d,i)=>{
                        return (
                            <a key={i} href={d.url}>{d.label}</a>
                        )
                    })}
                    <Typography gutterBottom>
                    </Typography>
                </DialogContent>
            </InfoDialog>
        </div>
    );
});