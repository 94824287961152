import React, {useState,  useEffect, useCallback, createContext } from 'react';


import TermsOfUseDialog from  "./TermsOfUseDialog";

import "./css/sanitize.css";
import "./css/index.css";


import Section1 from "./section1";


function Dashboard() {

    return (
        <>
            <TermsOfUseDialog/>

            <div>
                <header>
                    <a href="https://sustainacraft.com">
                        <img className="logo" src="img/logo.png"></img>
                        </a>
                </header>
            </div>

        
            <div className="content">
                <div className="conent-haeder">
                    <h1 className="content-title">Forest Carbon Projects</h1>
                    <p className="section-title">This map visualizes forest carbon projects in voluntary credit market and their associated information using open data. It is designed to provide an overview around each project using several parameters, such as forest cover, forest gain or loss. Data is mainly available for South America, Southeast Asia, Southwest Asia and Africa.</p>
                    <p className="section-title">New data layers will be added in the future.</p>
                </div>

                <section>
                    <Section1></Section1>
                </section>
            </div>

            <footer>
                <small>(c) 2021 sustainacraft</small>
            </footer>            
    </>
    );
}

export default Dashboard;