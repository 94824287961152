import { GeoJsonLayer } from "deck.gl";

import { KMLLoader } from "@loaders.gl/kml";


const iconConfig = {
    url: "./img/marker.png",
    x: 0,
    y: 0,
    anchorY: 512,
    width: 512,
    height: 512,
    mask: true
}


export default ({ data }) => {
    console.log("kml", data)

    //verraからKMLが公開されていない場合、また手動でKMLを補完していない場合は何も表示しない
    if (!data || (data.kml_url.length <= 0  && !data.comp_kml)) return null;

    
    let layers = [];
    let filePaths = []

    if (data.comp_kml){
        //補完されたKMLを読み込む
        filePaths = ["./data/verra/complement_kml/" + data.id + ".kml"]
    }else if (data.kml_url.length ==  1 ){
        //verraからダウンロードしたKMLが１つの場合
        filePaths = ["./data/verra/kml/" + data.id + ".kml"]
    }else{
        //verraからダウンロードしたKMLが複数の場合
        filePaths = data.kml_url.map((k, i) => {
            return "./data/verra/kml/" + data.id + "_" + i +".kml"
        })
    }

    for (let i in filePaths){
        const layer = new GeoJsonLayer({
            id: "geojson-layer" + i,
            data: filePaths[i],
            loaders: [KMLLoader],
            pickable: false,
            stroked: true,
            filled: true,
            pointType: "circle",
            getPointRadius: 10,
            getFillColor: [0, 160, 0, 0],
            getLineColor: [233, 163, 38, 255],
            lineWidthUnits:"pixels",
            getLineWidth:2,
            lineWidthMinPixels: 1
        });

        layers.push(layer)

    }

    return layers;
}
