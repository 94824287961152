

import React, { memo } from 'react';
//mui table elemets
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';




export default memo((props) => {
    const { rows, selectedID, handlerOnTrClick} = props;

    if(!rows) return null;

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper} sx={{ height: 440, minWidth: 650}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ '& th':{backgroundColor: "#089bad", color: "#fff" }}}>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="center">Country</TableCell>
                            <TableCell align="center">Methodology</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Proponent</TableCell>
                            <TableCell align="center">Area Hectares</TableCell>
                            <TableCell align="center">Annual Reductions</TableCell>
                    </TableRow>
                </TableHead>
                    <TableBody>
                        {
                        rows.map((row) => {
                        let sx = {
                            '& td': { whiteSpace: "nowrap"},
                            '&:last-child td, &:last-child th': { border: 0 }}
                            if (row.id == selectedID){
                                sx["backgroundColor"] = "#ccc";
                            } 
                        return (
                            <TableRow
                                key={row.id}
                                sx={sx}
                                height={30}
                                onClick={() => handlerOnTrClick(row.id)}
                            >
                                <TableCell align="right"><a target="_blank" href={row.url}>{row.ID}</a></TableCell>
                                <TableCell align="right">{row.Country}</TableCell>
                                <TableCell align="right">{row.Methodology}</TableCell>
                                <TableCell align="right">{row.Status}</TableCell>
                                <TableCell align="right">{row.Proponent}</TableCell>
                                <TableCell align="right">{row.Hectares}</TableCell>
                                <TableCell align="right">{row["Estimated Annual Emission Reductions"]}</TableCell>

                            </TableRow>
                            )
                        })
                    }

                    <TableRow style={{ height: 53 * 6 , border:0}}>
                        <TableCell colSpan={6} />
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

        {/*}
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {*/}
        </Paper>
    )

});