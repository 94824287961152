import React, { memo } from 'react';


//レイアウト用ボックス
import Box from '@mui/material/Box';

//カード類
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

//タイポブラフィ
import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';

import LegendItem from './LegendItem';

export default memo(({ layerStatus }) => {


    const legends = Object.keys(layerStatus).map(key=>{
        const show = layerStatus[key].show; 
        const name = layerStatus[key].name;
        const type = layerStatus[key].legendType;
        const l = layerStatus[key].legend;
        const height = layerStatus[key].height
        return { key: key, show: show, name: name, type: type, value: l, height: height}
    })
    .filter(d => d.value)
    .filter(d => d.show)


    if(legends.length == 0) return null;


    const GenerateLgend = ({data})=>{
        return (
            <Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                    {data.name}
                </Typography>

                {data.value.map((dd,i) => {
                    return (
                        <LegendItem key={i} color={dd.color} label={dd.label} height={dd.height}/>
                    )
                })}

            </Box>
        )
    }


    return (
        <Card
            sx={{
                position:"absolute",
                top:70,
                right:20,
                width: 200,
                p:0,
                overflowY: "auto",
                "::-webkit-scrollbar": {
                    width: 8,
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: "#ccc",
                    borderRadius: 100
                },
                "::-webkit-scrollbar-thumb": {
                    "backgroundColor": "#446",
                    "borderRadius": 100
                }
            }}
            variant="outlined"
        >
            <CardContent>
                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                    Legend
                </Typography>

                {legends.map((d, i) =>{
                    switch(d.type){
                        case "generate":
                            return (<GenerateLgend key={d.key} data={d} />)
                        break;
                        case "image":
                            return (<img width="100px" key={d.key} src="data/geocarbon/legend.svg" />)
                        break;

                    }
                })}
        
            </CardContent>
        </Card>
    )
});