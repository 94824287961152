//レイヤーズ
import MakerLayer from './MarkerLayer'
import KMLLayer from './KMLLayer'
import AgriculturalRevenueLayer from './AgriculturalRevenueLayer';

import ForestLossLayer from './ForestLossLayer';
import ForestGainLayer from './ForestGainLayer';
import ForestCoverLayer from './ForestCoverLayer';
import GeoCarbonForestBiomass from './GeoCarbonForestBiomass';



export default ({ 
        layerStatus,
        handlerOnMarkerClick
    }) => {
    
    let reslut = [];

 

    if (layerStatus["Agricultural_Revenue"].show) reslut.push(AgriculturalRevenueLayer(layerStatus["Agricultural_Revenue"]));

    if (layerStatus["Forest_Cover"].show){
        reslut.push(ForestCoverLayer(layerStatus["Forest_Cover"]))
    } 
    if (layerStatus["Forest_Gain"].show) {
        reslut.push(ForestGainLayer(layerStatus["Forest_Gain"]))
    } 

    if (layerStatus["Forest_Loss"].show) {
        reslut.push(ForestLossLayer(layerStatus["Forest_Loss"]));
    }


    if (layerStatus["GeoCarbon_forest_biomass"].show) {
        reslut.push(GeoCarbonForestBiomass(layerStatus["GeoCarbon_forest_biomass"]));
    }



    if (layerStatus["Forest_Carbon_Projects"].show){
        reslut.push(KMLLayer({
            ...layerStatus["Forest_Carbon_Projects"],
            //単体で選択されたデータのみKMLを表示するする
            data: layerStatus["Forest_Carbon_Projects"].data.filter(d => {
                if (!layerStatus["Forest_Carbon_Projects"].filter_selectedId) return false;
                return layerStatus["Forest_Carbon_Projects"].filter_selectedId.indexOf(d.ID) > -1;
            })[0]
        }))

        reslut.push(MakerLayer({
            ...layerStatus["Forest_Carbon_Projects"],
            data: layerStatus["Forest_Carbon_Projects"].data.filter(d => {
                return layerStatus["Forest_Carbon_Projects"].filter_showId.indexOf(d.ID) > -1;
            }),
            onClick: handlerOnMarkerClick,
        }))

    }


    return reslut.flat();
}