import React, {memo} from 'react';

import MenuItem from '@mui/material/MenuItem';

//タイポブラフィ
import Typography from '@mui/material/Typography';


//リスト
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

//アイコン・ボタンラッパー
import IconButton from '@mui/material/IconButton'

//アイコン
import InfoIcon from '@mui/icons-material/Info';

//ツールチップ
import Tooltip from '@mui/material/Tooltip';

//スイッチボタン
import Switch from '@mui/material/Switch';

//アイコン
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default memo(({ layerID, name, show, onInfoBtnClick, onSwitchChange}) => {


    return (
        <Tooltip title={name}>
            <MenuItem>
                <ListItemText>
                    <Typography variant="inherit" noWrap>{name}</Typography>
                </ListItemText>
                <ListItemIcon>
                    <IconButton
                        sx={{ pr: 0.5 }}
                        value={layerID}
                        onClick={onInfoBtnClick}
                    >
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </ListItemIcon>

                {show ?

                    <ListItemIcon>
                        <IconButton
                            sx={{ pl: 0.5, pr:0}}
                            value={layerID}
                            onClick={onSwitchChange}
                        >
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    </ListItemIcon>                
                : 

                    <ListItemIcon>
                        <IconButton
                            sx={{ pl: 0.5, pr: 0 }}
                            value={layerID}
                            onClick={onSwitchChange}
                        >
                            <VisibilityOffIcon fontSize="small" />
                        </IconButton>
                    </ListItemIcon>                
                }
            </MenuItem>
        </Tooltip>  
    );
});