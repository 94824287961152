import { BitmapLayer } from '@deck.gl/layers';



let memo_imageDataset = [];

export default (props) => {
    const { data,data_bbox, layer_opacity } = props;
    if (!data || !data.length > 0) return null;


    let imageDataset = [];





    if (imageDataset.length == 0) for (let idx in data) {
        const img = data[idx];
        imageDataset.push({
            ...data[idx],
            bbox:data_bbox[idx],
            img
        });
    }

    let layes = [];

    for (let idx in imageDataset) {
        const layer = new BitmapLayer({
            id: 'geocarbon-layer' + imageDataset[idx].bbox.join(":"),
            pickable: false,
            opacity: layer_opacity,
            bounds: imageDataset[idx].bbox,
            image: imageDataset[idx].img,
        });

        layes.push(layer);
    }

    return layes;

}