import React, {memo} from 'react';

import Icon from '@mui/material/Icon';

import "../../css/animation.css";


export default memo(({status, onClick}) => {


    return (
        <div
            id="detail-window"
            className={status.open ? "slideIn" : "slideOut"}
            style={{
                position: "absolute",
                bottom:"0",
                left:"calc(50% - (60%/2))",
                width: "60%",
                color:"black",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding:"1.5rem",
                fontSize: "12px",
                lineHeight: "1.25rem",
            }}
        >
            <Icon 
                sx={{
                    position: "absolute",
                    cursor: "pointer", 
                    right: -10,
                    top: -10,
                    fontSize: 30,
                    transform: "rotate(45deg)",
                }}
                onClick={onClick}
            >add_circle</Icon>
            <div style={{
                maxHeight: 280,
                overflowY: "auto",
                overflowX: "hidden"
            }}>
                <p style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "1.5rem"
                }}>
                    {status.title} (<a target="_blank" href={status.url}>verra</a>)
                </p>
                <p>
                    {status.body}
                </p>
            </div>
        </div>
    )
});