import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiBackdrop-root': {
        "backgroundColor":"rgba(0, 0 , 0, 0.7)"
    }

}));



const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default memo(() => {
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    sx={{backgroundColor:"#222", color:"white"}} 
                    id="customized-dialog-title" 
                    onClose={handleClose}>
                    Terms of Use
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        You agree that your use of this website shall be at your sole risk. We make no warranties or representations about the accuracy or completeness of this website’s content or the content of any website linked to this website, and assumes no liability or responsibility for any claim damage or loss whatsoever which may arise or result from such use or loss of use of, data in connection with the use of this website.
                    </Typography>
                </DialogContent>
                <DialogActions 
                    sx={{ backgroundColor: "#222", color: "white" }} 
                >
                    <Button  onClick={handleClose}>
                        Accept
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
});