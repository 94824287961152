import { MVTLayer, TileLayer, GridCellLayer, GeoJsonLayer } from 'deck.gl';
import { MVTLoader } from '@loaders.gl/mvt';
import { scaleThreshold } from "d3-scale";
import { color as d3color } from "d3-color";





//16進のカラーコードをrgbの配列に変換する
d3color.prototype.toArray = function () { return [this.r, this.g, this.b] }


const url = "https://ggg-vectortiles.s3.ap-northeast-1.amazonaws.com/lat_10per_extracted/{z}/{x}/{y}.pbf"

export default (props) => {
    const { colorScale, layer_opacity } = props;



    const getColor = (value) => {
        const v = colorScale(value)
        return d3color(v).toArray();
    }

    const lhexlayer = new MVTLayer({
        id: "hex",
        data: url,
        pickable: false,
        minZoom: 0,
        maxZoom: 11,
        stroked: false,
        binary: false,
        pointRadiusMinPixels: 1,
        getPointRadius: 1,
        getLineWidth: 1,
        lineWidthMinPixels: 1,
        /*
        updateTriggers: {
            getFillColor: [flag]
        },
        */
        parameters: {
            [2929]: false,
        },
        onTileError: (e) => { },
        renderSubLayers: (props) => {
            const {
                bbox: { west, south, east, north }
            } = props.tile;


            return new GridCellLayer(props, {
                data: props.data,
                cellSize:20000 ,
                extruded: false,
                offset: [0, 0],
                getPosition: d => d.geometry.coordinates,
                getFillColor: (d, i) => {
                    const p = d.properties;
                    //console.log(p)
                    const agrev = +p.agrev_yr_max_00_10;
                    if (isNaN(agrev)) return [0, 0, 0, 0];
                    return getColor(agrev);
                },
            });
        }
    });



    return [lhexlayer];
}
