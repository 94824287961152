import React, {memo} from 'react';


//dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

//progressbar
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';





export default memo(({ open }) => {

    return (

        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Loading...."}
            </DialogTitle>
            <DialogContent>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 200, mr: 1 }}>
                        <LinearProgress />
                    </Box>
                </Box>

            </DialogContent>
        </Dialog>

    )
});